import { createRouter, createWebHistory } from 'vue-router';
import { getStorage } from '@/utils/storage';
import formatRoutes from '@/utils/formatRoutes';
import Store from "@/store/index";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/layout'),
        redirect: '/welcome',
        children: []
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/customerDemand',
        name: 'customerDemand',
        component: () => import('@/views/customerDemand')
    },
    {
        path: '/demandDetail',
        name: 'demandDetail',
        component: () => import('@/views/demandDetail')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/pages/404'),
    },
    {
        path: '/:pathMatch(.*)',
        name: 'bad-not-found',
        component: () => import('@/views/pages/404'),
    },
]

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    console.log('路由前置====', to, from, Store.state.system.keepAlive);
    // 判断登录状态
    if (Store.getters['admin/authenticated']) {
        // 添加tab
        if (to.meta.title) {
            Store.commit('system/addTabbar', {
                title: to.meta.title,
                name: to.name,
                path: to.path,
                query: to.query,
            })
        }
        // 添加breadcrumb
        if (to.meta.location && to.meta.location.length > 0) {
            Store.commit('system/setLocation', to.meta.location);
        }
        // 设置页面缓存
        if (to.meta?.keepAlive) {
            Store.commit('system/setKeepAlive', to.name);
        }
        // 动态路由
        if (!Store.state.system.asyncRoutesMark) {
            const asyncRoutes = formatRoutes(getStorage('NAVIGATION'));
            asyncRoutes.forEach(item => {
                router.addRoute('home', item);
            })
            Store.commit('system/setAsyncRoutesMark', true);
            console.log('addRoute', router.getRoutes());
            next({ path: to.path, query: to.query });
        } else {
            next();
        }
    } else {
        if (to.name == 'demandDetail' || to.name == 'customerDemand') {
            next();
        } else if (to.name !== 'login') {
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next();
        }
    }
})

export default router;
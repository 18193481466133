import ActionPanel from "./page/actionPanel";
import SearchPanel from "./page/SearchPanel";
import BasicTable from "./page/BasicTable";
import FormModal from "./page/FormModal";
import EditForm from "./page/EditForm";
import EditTable from "./page/EditTable";
import SelectList from "./page/SelectList";
import ColumnPanel from "./page/ColumnPanel";
import StatusTag from "./page/StatusTag";
import TableModal from "./page/TableModal";
import Editor from "./page/Editor";
export default {
    install(app) {
        app.component('ActionPanel', ActionPanel);
        app.component('SearchPanel', SearchPanel);
        app.component('BasicTable', BasicTable);
        app.component('FormModal', FormModal);
        app.component('EditForm', EditForm);
        app.component('EditTable', EditTable);
        app.component('SelectList', SelectList);
        app.component('ColumnPanel', ColumnPanel);
        app.component('StatusTag', StatusTag);
        app.component('TableModal', TableModal);
        app.component('Editor', Editor);
    }
}
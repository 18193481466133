import {nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {getActionRulesApi, getSearchRulesApi} from "@/api";
import {getItem} from "@/utils/storage";
import {debounce} from "@/utils/tools";
import http from "@/utils/http";
import i18n from "@/language";
import upload from "@/utils/upload";
import download from "@/utils/download";

const {t} = i18n.global;


/**
 * 获取表格数据
 * @param {String} webTableName 页面表名
 * @param alias 别名
 * @param {Boolean} getData 首次自动加载数据
 * @returns
 */
export function useDataSource(webTableName, alias = "", getData = true) {

    // 路由
    const route = useRoute();
    const router = useRouter();
    // 加载
    const loading = ref(false);
    // 表格字段
    const columns = ref([]);
    // 数据
    const datasource = ref([]);
    // 分页
    const pagination = ref({
        page: 1,
        size: 20,
        total: 0,
    });
    // 查询表单
    const expertSearch = ref({});
    // 简单搜索
    const simpleSearch = ref("");
    // 总结栏
    const summaryRow = ref(undefined);
    // 选中行
    const selectedKeys = ref([]);
    const selectedRows = ref([]);
    // 表格高度
    const tableHeight = ref(0);
    // 操作按钮
    const actionRules = ref([]);
    // 搜索表单
    const searchRules = ref([]);
    // 表单配置
    const fromOption = ref({
        visible: false,
        title: "",
        width: 500,
        labelWidth: 100,
        postApi: "",
    });
    // 表单规则
    const formRules = ref([]);
    // 表单数据
    const formData = ref({});
    // 显示高级搜索
    const searchVisible = ref(false);
    // 列设置
    const columnVisible = ref(false);

    onMounted(() => {
        console.log("挂载 ===", webTableName);
        // 将路由传参赋值给查询条件
        expertSearch.value = route.query;
        // 自动加载
        if (getData) {
            // 获取配置
            getPageRules();
            // 获取数据
            getDataList();
        }
    });
    onUnmounted(() => {
        console.log("卸载 ===", webTableName);
    });


    /**
     * 显示隐藏高级搜索
     */
    function showExpertSearch() {
        searchVisible.value = !searchVisible.value;
    }

    /**
     * 显示列设置
     */
    function showColumnConfig() {
        columnVisible.value = true;
    }

    // 操作事件表单规则
    function handleActionFormRules(action, width, labelWidth, pk = "") {
        if (action !== "create") {
            if (!pk && selectedKeys.value.length !== 1) {
                window.$message.error(t("selectRowMsg"));
                return false;
            }
            pk = pk ? pk : selectedKeys.value[0];
        }
        getFormRules({type: action, pk})
            .then(() => {
                fromOption.value.width = width;
                fromOption.value.labelWidth = labelWidth;
                fromOption.value.title = t(action);
                fromOption.value.postApi = webTableName + "/" + action;
                fromOption.value.visible = true;
            })
            .catch((err) => console.log(err));
    }

    // 获取表单规则数据
    function getFormRules(params) {
        return new Promise((resolve, reject) => {
            http.get(webTableName + "/rules", {params}).then(res => {
                if (res) {
                    // 上传图片组件
                    res.form_rules.forEach(element => {
                        if (element.type === "upload") {
                            element.props.accept = "image\/*";
                            element.props.max = 1;
                            element.props.action = process.env.VUE_APP_BASE_URL + "/test/qiniu";
                            element.props.headers = {
                                "authorization": getItem("TOKEN")
                            };
                            element.props.onSuccess = function (res) {
                                if (res.errno === 0) {
                                    // 提交原图
                                    element.value = res.data[0];
                                    formRules.value.forEach(values => {
                                        if (values.field === "da" + element.field) {
                                            values.value = res.data[1];
                                        }
                                    });
                                }
                            };
                            element.props.onRemove = function () {
                                formData.value[element.field] = "";
                                formRules.value.forEach(values => {
                                    if (values.field === "da" + element.field) {
                                        values.value = "";
                                    }
                                });
                            };
                        }
                    });
                    formRules.value = res.form_rules;
                    formData.value = res.form_data;
                    resolve(res);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }


    /**
     * 提交搜索
     */
    function expertSearchSubmit() {
        getDataList(1);
    }

    /**
     * 重置搜索
     */
    function expertSearchReset() {
        // 清空表单
        Object.keys(expertSearch.value).map((key) => {
            expertSearch.value[key] = null;
        });
        getDataList(1);
    }


    /**
     * 操作按钮，搜索配置
     */
    function getPageRules() {
        // 操作配置
        getActionRulesApi({webTableName: webTableName + alias})
            .then(res => {
                actionRules.value = res;
            }).catch(() => {
        });
        // 查询配置
        getSearchRulesApi({webTableName: webTableName})
            .then(res => {
                searchRules.value = buildSearchRules(res);
                // 添加搜索按钮
                let rules = res.filter(
                    (item) => item.type !== "hidden"
                );
                if (rules.length > 0) {
                    searchRules.value.push(
                        {
                            type: "n-col",
                            children: [
                                {
                                    type: "div",
                                    style: {
                                        display: "flex",
                                        gap: "10px",
                                    },
                                    children: [
                                        {
                                            type: "n-button",
                                            name: "btn",
                                            props: {
                                                type: "primary",
                                                field: "btn",
                                                size: "small",
                                            },
                                            on: {
                                                click: expertSearchSubmit,
                                            },
                                            children: [t("search")],
                                        },
                                        {
                                            type: "n-button",
                                            name: "btn",
                                            props: {
                                                field: "btn",
                                                size: "small",
                                            },
                                            on: {
                                                click: expertSearchReset,
                                            },
                                            children: [t("reset")],
                                        },
                                    ],
                                },
                            ],
                        }
                    );
                }
            })
            .catch(() => {
            });
    }

    /**
     * 搜索规则
     * @param searchRules
     * @returns {*}
     */
    function buildSearchRules(searchRules) {
        return searchRules.map(item => {
            // 选择器搜索事件
            if (item.type === "select"  && item.props?.remote) {
                item.props.loading = false;
                item.props.remote = true;
                item.props["onSearch"] = (keyword) => {
                    handleSelectionSearch(item.field, keyword);
                };
                item.props["onClear"] = (keyword) => {
                    handleSelectionSearch(item.field, keyword);
                };
            }
            return item;
        });
    }

    /**
     * 选择器搜索异步加载数据
     * @param query
     */
    const handleSelectionSearch = debounce(getSelectionOptions, 300);
    function getSelectionOptions(field, keyword) {
        let params = {webTableName, field, keyword, type: 'search'};
        searchRules.value.map(item => {
            if (item.field === field) {
                item.props.loading = true;
            }
        });
        http.get("rules/selectionOptions", {params}).then(res => {
            searchRules.value.map(item => {
                if (item.field === field) {
                    item.value = null;
                    item.options = res;
                }
            });
        }).finally(() => {
            searchRules.value.map(item => {
                if (item.field === field) {
                    item.props.loading = false;
                }
            });
        });
    }

    /**
     * 获取列表数据
     * @param {number} page 页码
     * @param {number} size 每页数量
     */
    async function getDataList(page = 0, size = 0) {
        // 请求参数
        let params = {page: pagination.value.page, size: pagination.value.size};
        if (typeof page === "number" && page > 0) params.page = page;
        if (typeof size === "number" && size > 0) params.size = size;
        // 搜索参数
        if (expertSearch) {
            params = {...params, ...expertSearch.value, ...{simple_search: simpleSearch.value}};
        }
        // 清空选中
        selectedKeys.value = [];
        selectedRows.value = [];

        // 发起请求
        loading.value = true;
        http.get(webTableName + "/list", {params})
            .then(res => {
                loading.value = false;
                // 请求成功，处理数据列表和分页信息
                if (res) {
                    let selection = {
                        type: "selection",
                        width: 40,
                        show: true,
                        fixed: "left"
                    };
                    columns.value = [selection, ...res.columns];
                    datasource.value = res.list;
                    summaryRow.value = () => {
                        return res.summary;
                    };
                    pagination.value = {
                        page: res?.page?.page ?? 1,
                        size: res?.page?.size ?? 99999,
                        total: res?.page?.total ?? 0
                    };
                }
            }).catch(() => {
            loading.value = false;
        });
    }


    /**
     * 删除
     * @param {function} callback 回调
     * @returns
     */
    function handleDelete(callback = () => {
    }) {
        if (selectedKeys.value.length < 1) {
            window.$message.error(t("deleteSelectMsg"));
            return false;
        }
        window.$dialog.warning({
            title: t("warning"),
            content: t("deleteRowMsg", {num: selectedKeys.value.length}),
            positiveText: t("ok"),
            negativeText: t("cancel"),
            transformOrigin: "center",
            onPositiveClick: () => {
                deleteRows(callback);
            },
        });
    }

    /**
     * 发起删除请求
     */
    function deleteRows(callback) {
        loading.value = true;
        http.post(webTableName + "/delete", {pks: selectedKeys.value})
            .then(res => {
                loading.value = false;
                if (res) {
                    window.$message.success(t("successMsg"));
                    // 清空选中
                    selectedKeys.value = [];
                    selectedRows.value = [];
                    callback(res);
                }
            })
            .catch(() => {
                loading.value = false;
                // callback(err);
            });
    }


    return {
        loading,
        columns,
        pagination,
        datasource,
        expertSearch,
        simpleSearch,
        actionRules,
        searchRules,
        fromOption,
        formRules,
        formData,
        selectedKeys,
        selectedRows,
        tableHeight,
        summaryRow,
        searchVisible,
        columnVisible,
        getDataList,
        getFormRules,
        getPageRules,
        handleActionFormRules,
        handleDelete,
        showExpertSearch,
        showColumnConfig,
    };
}
export default {
    prodname: '外贸系统管理后台',
    home: '首页',
    language: '简体中文',
    profile: '个人资料',
    logout: '退出登录',
    closeCurrent: '关闭当前',
    closeOther: '关闭其他',
    closeAll: '关闭全部',
    serverError: "服务器错误",
    create: "新增",
    add: "新增",
    edit: "编辑",
    save: "保存",
    done: "完成",
    search: "搜索",
    reset: "重置",
    ok: "确定",
    copy: "复制",
    cancel: "取消",
    confirm: "提示",
    warning: "警告",
    pageInfo: "共 {num} 条",
    changeLanguageMsg: "切换语言将会重新加载页面, 是否继续?",
    changeCompanyMsg: "切换公司将会重新加载页面, 是否继续?",
    changeSetofbookMsg: "切换账套将会重新加载页面, 是否继续?",
    deleteRowMsg: "确定删除选择的 {num} 条数据吗？",
    deleteSelectMsg: "请选择至少一条要删除的数据!",
    editSelectMsg: "请选择一条要编辑的数据！",
    inviteSelectMsg: "请选择邀请的员工数据！",
    successMsg: "操作成功！",
    fullScreenMsg: "进入全屏模式失败，浏览器不支持！",
    columnRules: "列配置",
    undoTableDataMsg: "确定撤销已编辑数据吗？",
    selectRowMsg: "请选择一条数据！",
    password: "密码",
    resetPassword: "重置密码",
    changePassword: "修改密码",
    phone: "电话",
    changePhone: "修改手机号",
    setPermissions: "设置权限",
    accountRule: "请输入账号",
    passwordRule: "请输入密码",
    captchaRule: "请输入验证码",
    isAuditMsg: "该数据已审核，不能编辑！",
    isFinishMsg: "该数据已完成，不能编辑！",
    doAuditMsg: "你正在进行审核操作, 是否继续?",
    noAuditFinishMsg: "数据未审核，不能进行完成操作！",
    noAuditReceivingMsg: "数据未审核，不能收货！",
    noAuditPaydepositMsg: "数据未审核，不能付定金！",
    noAuditStuffingMsg: "数据未审核，不能装柜！",
    doAuditToContinue: "请先完成审核操作，再继续！",
    selectProduct: "选产品",
    insertRowMsg: "请勾选要在哪行数据前面插入！",
    removeRowMsg: "请勾选要删除的行！",
    copyRowMsg: "请勾选要复制的行！",
    copyRowSuccessMsg: "已复制所选行！",
    pasteRowMsg: "请勾选要在哪行数据前面粘贴！",
    pasteRowCopyMsg: "请先复制要粘贴的行！",
    total: "合计",
    setRole: "分配角色",
    setDataScope: "数据权限",
    setpermissions: "设置权限",
    noSelectCustomerMsg: "请先选择客户！",
    inviteUrl: "邀请链接",
    copyInviteUrlMsg: "邀请链接已复制！",
    copyInviteUrlErroeMsg: "当前环境不支持，请手动复制！",
    expandForm: "展开表单",
    foldForm: "折叠表单",
    tableFullscreen: "表格全屏",
    exitFullscreen: "退出全屏",
    title: "标题",
    columnWidth: "列宽",
    displayOrder: "显示顺序",
    visible: "是否显示",
    createStuffing: "插入装柜单",
    selectContainer: "选择柜子",
    shippingContents: "装柜内容",
    shippingImages: "装柜图片",
    shippingDetail: "装柜明细",
    shippingCost: "装柜费用",
    noAuditMakeInvoiceMsg: "请审核装柜单后，再点击做发票按钮！",
    makeInvoice: "做发票",
    noInvoiceMsg: "当前发票还未生成！",
    isInvoicedMsg: "当前发票已生成，无需重复生成！",
    isAllInvoicedMsg: "发票已全部生成，无需重复生成！",
    selectPurchaseNoProductNoMsg: "请先选择采购单号！",
    doRemoveImageMsg: "确定删除这张图片吗？",
    selectSurplus: "选余货",
    createPurchase: "生成采购单",
    createReceiving: "生成收货单",
    createShipping: "生成装柜单",
    price: "采购单价",
    salePrice: "销售单价",
    ExportOptions: "导出选项",
    selectOrder: "选订单",
    noSupplierMsg: "请先选择供应商！",
    copySelectedRows: "复制选中行",
    removeSelectedRows: "删除选中行",
    receivableWarning: "科目为A开头的才可以做应收记录!",
    customWarning: "请选择一个客户名称!或者进入应收登记界面直接登记!",
    dataReview: "数据未审核,请审核后再进行应收操作!",
    selectBoxContainerSn: "请选择柜子内部编号!",
    selectBoxNumber: "请选择柜子箱号!",
    selectCustomerNo: "请选择客户唛头!",
    selectedCoutomerMsg: "所选客户不属于该柜子, 是否继续!",
    operationCanceled: "已取消操作!",
    nearlyWeek: "近一周",
    nearlyMonth: "近一月",
    nearlyYear: "近一年",
    faqs: "常见问题",
    quickActions: "快捷操作",
    warningInformation: "预警信息",
    stock: "库存",
    bankBalance: "银行余额",
    payable: "应付款",
    receivable: "应收款",
    profit: "利润",
    newSales: "新增销售单",
    newPurchase: "新增采购单",
    newDelivery: "新增收货单",
    newShipping: "新增装柜单",
    newInvoice: "新增发票",
    selectPurchase: "选择采购单",
    selectInventory: "选库存",
    createInvoice: "生成发票",
    payableSummary: "应付账款汇总",
    payableWarning: "应付预警",
    businessWarning: "业务预警",
    enterAccount: "请输入账号",
    enterPassword: "请输入密码",
    enterCaptcha: "请输入验证码",
    setRoles: "分配角色",
    checked: "已审核",
    finished: "已完成",
    received: "已收货",
    loaded: "已装柜",
    confirmed: "已确认",
    selectContainerMsg: "请点击前面的按钮，选择一个存在的柜子内部编号，或在后方的输入框中直接输入一个新的柜子内部编号",
    deleteRowErrorMsg: "行号【{seq}】已生成下级模块数据，禁止删除！",
    selectRowHasMultiVenMsg: "已选择记录中包含多个供应商，不能合并生成付款单！",
    restoreBackup: "还原备份",
    restoreBackupMsg: "还原备份可能导致部分新数据丢失，请谨慎操作！",
    selectReceivingPlanRowMsg: "请选择做收货计划的数据！",
    selectRowHasMultiCusMsg: "已选择记录中包含多个客户，不能生收货计划！",
    selectPurchaseNoCustomerNoMsg: "请先选择客户！",
    exchrateNotZeroMsg: "汇率必须大于0！",
    exchrateNotZeroRowMsg: "行号：{seq}，汇率必须大于0！",
    tableDataCurrencyInconformityMsg: "表格数据币种不一致！",
    sameCurrencyExchrateOnlyOneRowMsg: "行号：{seq}，付款币种与单据币种一致，汇率只能为1！",
    differentCurrencyExchrateNotOneRowMsg: "行号：{seq}，付款币种与单据币种不一致，汇率不能为1！",
    outAccount: "支出账号",
    bankAccount: "银行账号",
    currency: "币种",
    outCurrency: "支出币种",
    depositCurrency: "定金币种",
    settlementCurrency: "结算币种",
    purCurrency: "采购单币种",
    selectSubjectMsg: "请选择科目！",
    selectCustomeMsg: "请选择客户唛头！",
    inputAmountMsg: "请输入金额！",
    selectCurrencyMsg: "请选择币种！",
    selectSettlementCurrencyMsg: "请选择结算币种！",
    inputExchangeRateMsg: "请输入汇率！",
    selectOrderMsg: "请选择采购单！",
    depositMustSameMsg: "退定金币种必须与订单币种或定金币种其中一项相同!",
    selectOutAccountMsg: "请选择支出账号！",
    selectInAccountMsg: "请选择收入账号！",
    outAndInAccountCannotSameMsg: "支出账号与收入账号不能相同！",
    selectOutCurrencyMsg: "请选择支出币种！",
    selectInCurrencyMsg: "请选择收入币种！",
    outAndInCurrencyCannotSameMsg: "支出币种与收入币种不能相同！",
    inputInAmountMsg: "请输入收入金额！",
    inputOutAmountMsg: "请输入支出金额！",
    outAndInAmountCannotBothMsg: "支出和收入不能同时输入数值，只能在其中一个项目中输入数值！",
    outAndInAmountCannotBothBookkeepingMsg: "预付款类型的记账，支出和收入的金额不允许同时有值！",
    purchaseOrder: "采购单",
    selectPurchaseOrder: "请选择采购单",
    search1: "查询",
    supplier: "供应商",
    selectSupplier: "请选择供应商",
    consignee: "收货人",
    selectConsignee: "请选择收货人",
    containerNO: "柜子箱号",
    selectContainerNO: "请选择柜子箱号",
    containerSN: "柜子内部编号",
    selectContainerSN: "柜子内部编号",

}
export default {
    prodname: 'Foreign trade management system',
    home: 'Home',
    language: 'English',
    profile: 'Profile',
    logout: 'Logout',
    closeCurrent: 'Close Current',
    closeOther: 'Close Other',
    closeAll: 'Close All',
    serverError: "Server Error",
    create: "Add",
    add: "Add",
    edit: "Edit",
    save: "Save",
    done: "Done",
    search: "Search",
    reset: "Reset",
    ok: "OK",
    copy: "Copy",
    cancel: "Cancel",
    confirm: "Confirm",
    warning: "Warning",
    pageInfo: "Total {num} items",
    changeLanguageMsg: "Switching languages will reload the page. Do you want to continue?",
    changeCompanyMsg: "Switching companies will reload the page. Do you want to continue?",
    changeSetofbookMsg: "Switching set of books will reload the page. Do you want to continue?",
    deleteRowMsg: "Are you sure to delete the selected {num} pieces of data?",
    deleteSelectMsg: "Please select at least one piece of data to delete!",
    editSelectMsg: "Please select a piece of data to edit!",
    inviteSelectMsg: "Please select invited employee data!",
    successMsg: "Success!",
    fullScreenMsg: "Failed to enter full screen mode, browser not supported!",
    columnRules: "Column Settings",
    undoTableDataMsg: "Are you sure to undo the edited data?",
    selectRowMsg: "Please select a piece of data!",
    password: "Password",
    resetPassword: "Reset password",
    changePassword: "Change password",
    phone: "Telephone",
    changePhone: "Modify mobile phone number",
    setPermissions: "Set permissions",
    accountRule: "Please enter account",
    passwordRule: "Please enter password",
    captchaRule: "Please enter captcha",
    isAuditMsg: "The data has been verified and cannot be edited!",
    isFinishMsg: "The data is complete and cannot be edited!",
    doAuditMsg: "You are in the middle of an audit operation, do you want to continue?",
    noAuditFinishMsg: "Data has not been reviewed and cannot be completed!",
    noAuditReceivingMsg: "Data has not been reviewed, can not receive goods!",
    noAuditPaydepositMsg: "Data has not been reviewed, no deposit can be paid!",
    noAuditStuffingMsg: "Data has not been reviewed, can not be loaded!",
    doAuditToContinue: "Please complete the audit before continuing!",
    selectProduct: "Select products",
    insertRowMsg: "Please select where to insert the row of data!",
    removeRowMsg: "Please select the row to be deleted!",
    copyRowMsg: "Please select the row to be copied!",
    copyRowSuccessMsg: "The selected row has been copied!",
    pasteRowMsg: "Please select where to paste the copied row!",
    pasteRowCopyMsg: "Please copy the row to be pasted first!",
    total: "Total",
    setRole: "Assign roles",
    setDataScope: "Data permissions",
    setpermissions: "Set permissions",
    noSelectCustomerMsg: "Please select the customer first!",
    inviteUrl: "Invite Url",
    copyInviteUrlMsg: "Invitation link copied!",
    copyInviteUrlErroeMsg: "The current environment does not support, please manually copy!",
    expandForm: "ExpandForm",
    foldForm: "FoldForm",
    tableFullscreen: "TableFullscreen",
    exitFullscreen: "ExitFullscreen",
    title: "Title",
    columnWidth: "Column Width",
    displayOrder: "Display Order",
    visible: "Visible",
    createStuffing: "Insert the loading list",
    selectContainer: "Select Container",
    shippingContents: "Contents",
    shippingImages: "Images",
    shippingDetail: "Shipping Detail",
    shippingCost: "Shipping Cost",
    noAuditMakeInvoiceMsg: "Please check the loading list and then click the invoice button!",
    makeInvoice: "Make an invoice",
    noInvoiceMsg: "The current invoice has not been generated!",
    isInvoicedMsg: "The current invoice has been generated, no need to repeat!",
    isAllInvoicedMsg: "All invoices have been generated, no need to repeat!",
    selectPurchaseNoProductNoMsg: "Please select purchase order number first!",
    doRemoveImageMsg: "Are you sure you want to delete this picture?",
    selectSurplus: "Select surplus",
    createPurchase: "Generate purchase order",
    createReceiving: "Generate a receipt",
    createShipping: "Generate the loading list",
    price: "Price",
    salePrice: "Sale Price",
    ExportOptions: "Export Options",
    selectOrder: "Select Order",
    noSupplierMsg: "Please select the supplier first!",
    copySelectedRows: "Copy selected row",
    removeSelectedRows: "Delete selected row",
    receivableWarning: "Only accounts starting with A can be recorded as accounts receivable!",
    customWarning: "Please select a customer name! Alternatively, enter the accounts receivable registration interface to register directly!",
    dataReview: "The data has not been approved. Please review before proceeding with the accounts receivable operation!",
    selectBoxContainerSn: "Please select the box container sn!",
    selectBoxNumber: "Please select the box number!",
    selectCustomerNo: "Please select the customer first!",
    selectedCoutomerMsg: "The selected customer does not belong to the cabinet, whether to proceed!",
    operationCanceled: "The operation has been canceled!",
    nearlyWeek: "nearly week",
    nearlyMonth: "nearly month",
    nearlyYear: "nearly year",
    faqs: "FAQS",
    quickActions: "Quick Actions",
    warningInformation: "Warning Information",
    stock: "Stock",
    bankBalance: "Bank Balance",
    payable: "Payable",
    receivable: "Receivable",
    profit: "Profit",
    newSales: "New Sales",
    newPurchase: "New Purchase",
    newDelivery: "New Delivery",
    newShipping: "New Shipping",
    newInvoice: "New Invoice",
    selectPurchase: "Select Purchase",
    selectInventory: "Select Inventory",
    createInvoice: "Create Invoice",
    payableSummary: "Payable Summary",
    payableWarning: "Payable Warning",
    businessWarning: "Business Warning",
    enterAccount: "Please enter account",
    enterPassword: "Please enter password",
    enterCaptcha: "Please enter captcha",
    setRoles: "Set Roles",
    checked: "Checked",
    finished: "Finished",
    received: "Received",
    loaded: "Loaded",
    confirmed: "Confirmed",
    selectContainerMsg: "Please click on the front button to select an existing cabinet internal number, or enter a new cabinet internal number directly in the input field at the back",
    deleteRowErrorMsg: "Row No. [{seq}] Lower-level module data has been generated, cannot deleted!",
    selectRowHasMultiVenMsg: "Selected records contain multiple vendors and cannot be combined to generate a payment order!",
    restoreBackup: "Restore Backup",
    restoreBackupMsg: "Restoring the backup may cause the loss of some new data. Exercise caution when performing this operation!",
    selectReceivingPlanRowMsg: "Please select the data for receiving plan!",
    selectRowHasMultiCusMsg: "Selected records contain multiple customers and cannot be generated receiving plan!",
    selectPurchaseNoCustomerNoMsg: "Please select the customer first!",
    exchrateNotZeroMsg: "The exchange rate must be greater than 0!",
    exchrateNotZeroRowMsg: "RowNo:{seq},The exchange rate must be greater than 0!",
    tableDataCurrencyInconformityMsg: "The data currencies in the table are inconsistent!",
    sameCurrencyExchrateOnlyOneRowMsg: "RowNo:{seq},The payment currency is the same as the document currency, and the exchange rate can only be 1!",
    differentCurrencyExchrateNotOneRowMsg: "RowNo:{seq},The payment currency is inconsistent with the document currency, the exchange rate cannot be 1!",
    outAccount: "OutAccount",
    bankAccount: "BankAccount",
    currency: "Currency",
    outCurrency: "OutCurrency",
    depositCurrency: "DepositCurrency",
    settlementCurrency: "SettlementCurrency",
    purCurrency: "PurCurrency",
    selectSubjectMsg: "Please select a subject!",
    selectCustomeMsg: "Please select the customer mark!",
    inputAmountMsg: "Please enter the amount!",
    selectCurrencyMsg: "Please select a currency!",
    selectSettlementCurrencyMsg: "Please select a settlement currency!",
    inputExchangeRateMsg: "Please enter the exchange rate!",
    selectOrderMsg: "Please select the purchase order!",
    depositMustSameMsg: "The refund deposit currency must be the same as the order currency or one of the deposit currencies!",
    selectOutAccountMsg: "Please select a spending account!",
    selectInAccountMsg: "Please select a revenue account!",
    outAndInAccountCannotSameMsg: "Expenditure account and income account can not be the same!",
    selectOutCurrencyMsg: "Please select payment currency!",
    selectInCurrencyMsg: "Please select the income currency!",
    outAndInCurrencyCannotSameMsg: "The currency of expenditure and the currency of income cannot be the same!",
    inputInAmountMsg: "Please enter the amount of income!",
    inputOutAmountMsg: "Please enter the amount spent!",
    outAndInAmountCannotBothMsg: "Expenditure and income cannot be entered at the same time, only one of the values can be entered!",
    outAndInAmountCannotBothBookkeepingMsg: "Prepayment type of bookkeeping, the amount of expenditure and income are not allowed to have value at the same time!",
    purchaseOrder: "Purchase Order",
    selectPurchaseOrder: "Please Select Purchase Order",
    search1: "Search",
    supplier: "Supplier",
    selectSupplier: "Please Select Supplier",
    consignee: "Consignee",
    selectConsignee: "Please Select Consignee",
    containerNO: "Container NO.",
    selectContainerNO: "Please Select Container NO.",
    containerSN: "Container SN",
    selectContainerSN: "Please Select Container SN",

}
<template>
    <!-- 主表表单 -->
    <div>
        <div
            v-if="formRules.length"
            class="form-wrapper"
            :class="{ 'form-collapsed':formCollapsed }"
        >
            <form-create
                v-model="formDataRef"
                v-model:api="formApi"
                :rule="formRulesRef"
                :option="formOptions"
            />
        </div>
    </div>
</template>
<script setup>
import {ref, computed} from "vue";

const props = defineProps({
    formCollapsed: {type: Boolean, default: true},
    formRules: {type: Array, default: []},
    formData: {type: Object, default: {}},
    disabled: {type: Boolean, default: true},
});

const emits = defineEmits(["update:formRules", "update:formData"]);

// 表单规则
const formRulesRef = computed({
    get: () => props.formRules,
    set: (val) => {
        emits("update:formRules", val);
    },
});

// 表单数据
const formDataRef = computed({
    get: () => props.formData,
    set: (val) => {
        emits("update:formData", val);
    },
});

// 表单配置
let formApi = {};
const formOptions = ref({
    form: {
        size: "small",
        showFeedback: false,
        labelWidth: 100,
        labelAlign: "left",
        labelPlacement: "left",
    },
    row: {
        gutter: [10, 10],
    },
    submitBtn: false,
});

// 暴露给父组件
defineExpose({
    formApi,
});
</script>
<template>
    <!-- 下拉菜单 -->
    <n-select
        v-model:value="selectValue"
        size="small"
        filterable
        :remote="remote"
        :options="selectOptions"
        :clearable="clearable"
        :readonly="readonly"
        :loading="loading"
        :disabled="inputDisabled != null ? inputDisabled : disabled"
        :placeholder="$t(placeholder)"
        @search="handleSelectSearch"
        @clear="handleSelectClear"
    />
    <!-- 查询按钮 -->
    <n-button
        v-if="useList"
        :disabled="findBtnDisabled != null ? findBtnDisabled : disabled"
        @click="handleSearch"
    >
        <i class="ri-search-line"></i>
    </n-button>
    <!-- 新增按钮 -->
    <n-button
        v-if="useAdd"
        :disabled="addBtnDisabled != null ? addBtnDisabled : disabled"
        @click="handleAdd"
    >
        <i class="ri-add-line"></i>
    </n-button>
    <!-- 查询列表弹窗 -->
    <table-modal
        v-model:visible="show"
        :webTableName="webTableName"
        :width="listWidth"
        :title="$t(queryTitle)"
        :filters="listFilters[webTableName]"
        :cancelBtn="$t('cancel')"
        :submitBtn="$t('ok')"
        @submit="onSubmit"
        @cancel="onCancel"
    />
    <!-- 新增编辑表单 -->
    <form-modal
        v-model:visible="form.visible"
        v-model:formData="formData"
        :title="form.title"
        :width="form.width"
        :labelWidth="form.labelWidth"
        :formRules="formRules"
        :postApi="form.postApi"
        @submitSuccess="handleSubmitSuccess"
    />
</template>
<script setup>
import {computed, ref} from "vue";
import {useDataSource} from "@/composables/dataSource";
import i18n from "@/language";

const {t} = i18n.global;

const props = defineProps({
    webTableName: String,
    modelValue: [String, Number],
    clearable: {type: Boolean, default: true},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    inputDisabled: {type: Boolean, default: null},
    findBtnDisabled: {type: Boolean, default: null},
    addBtnDisabled: {type: Boolean, default: null},
    loading: {type: Boolean, default: false},
    options: {type: Array, default: []},
    remote: {type: Boolean, default: false},
    useList: {type: Boolean, default: true},
    useAdd: {type: Boolean, default: false},
    createTitle: {type: String, default: "createTitle"},
    queryTitle: {type: String, default: "queryTitle"},
    queryPlaceholder: {type: String, default: "queryPlaceholder"},
    placeholder: {type: String, default: ""},
    status: {type: String, default: ""},
    listWidth: {type: String, default: "80vw"},
    class: String,
    style: Object,
    listFilters: {type: Object, default: {}},
});

// 事件
const emits = defineEmits(["update:modelValue", "update:options", "submit", "search", "clear"]);
// 当前选择
const selectValue = computed({
    get: () => props.modelValue,
    set: (val) => {
        emits("update:modelValue", val);
    },
});
// 选择列表数据
const selectOptions = computed({
    get: () => props.options,
    set: (val) => {
        emits("update:options", val);
    },
});
// 数据源
const {
    formRules,
    formData,
    getFormRules,
} = useDataSource(props.webTableName, "", false);

/**
 * 搜索事件
 * ==============================================
 */
// 显示搜索列表
const show = ref(false);
// 显示搜索列表
const handleSearch = () => {
    show.value = !show.value;
};
//
const onSubmit = (rows) => {
    selectValue.value = rows[0].zhujian;
    emits("submit", props.webTableName, rows[0]);
};
const onCancel = () => {
    console.log("onCancel");
};


/**
 * 新增事件
 * ==============================================
 */
// 新增表单
const form = ref({
    visible: false,
    title: t("add"),
    width: 880,
    labelWidth: 100,
    postApi: "",
});
// 添加
const handleAdd = () => {
    getFormRules({type: "create", uuid: ""})
        .then(() => {
            form.value.postApi = props.webTableName + "/selectListAdd";
            form.value.visible = true;
        })
        .catch((err) => console.log(err));
};

/**
 * 表单事件
 * ==============================================
 */
// 表单提交成功回调
const handleSubmitSuccess = (res) => {
    if (res) {
        selectOptions.value.push(res);
        selectValue.value = res.value;
    }
};


/**
 * 选择器搜索异步加载数据
 */
const handleSelectSearch = (keyword) => {
    emits('search', keyword);
}
const handleSelectClear = () => {
    emits('clear');
}

</script>
<template>
    <n-modal
        v-model:show="show"
        preset="dialog"
        transformOrigin="center"
        to="#pageRoot"
        :mask-closable="false"
        :showIcon="false"
        :title="$t('columnRules')"
        :loading="loading"
        :style="{ width:'900px' }"
        :footer-style="{ textAlign: 'right' }"
        :positive-text="$t('save')"
        :negative-text="$t('cancel')"
        :positive-button-props="{ disabled:tableLoading }"
        :negative-button-props="{ disabled:loading }"
        @positive-click="onSubmit"
        @after-enter="onShow"
        @after-leave="onCancel"
    >
        <n-scrollbar class="form-modal-wrapper">
            <n-data-table
                ref="dataTable"
                size="small"
                max-height="600px"
                :row-key="(rowData) => rowData['web_column_name']"
                :loading="tableLoading"
                :columns="columns"
                :data="tableData"
                :striped="true"
                :single-line="false"
                :paginate-single-page="false"
                :style="{ fontSize: '12px' }"
            >
            </n-data-table>
        </n-scrollbar>
    </n-modal>
</template>
<script>
import {computed, ref, h, defineComponent, nextTick} from "vue";
import {NInput, NInputNumber, NSwitch} from "naive-ui";
import {getColumnRulesApi, saveColumnRulesApi} from "@/api";
import i18n from "@/language";

export default defineComponent({
    name: "ColumnPanel",
    props: {
        webTableName: String,
        type: {type: String, default: "list"},
        visible: {type: Boolean, default: false},
    },
    emits: ["update:visible", "submitSuccess"],
    setup(props, {emit}) {
        const show = computed({
            get: () => props.visible,
            set: (val) => {
                emit("update:visible", val);
            },
        });
        const loading = ref(false);
        const tableLoading = ref(false);

        const {t} = i18n.global;

        // 表格列
        const columns = [
            {
                key: "header_text",
                title: t("title"),
                align: "center",
                width: 300,
                // render(row) {
                //     return h(ShowOrEdit, {
                //         value: row.header_text,
                //         type: "text",
                //         onUpdateValue(v) {
                //             row.header_text = v;
                //         },
                //     });
                // },
            },
            {
                key: "column_width",
                title: t("columnWidth"),
                align: "center",
                width: 180,
                render(row) {
                    return h(ShowOrEdit, {
                        value: row.column_width,
                        type: "number",
                        onUpdateValue(v) {
                            row.column_width = v;
                        },
                    });
                },
            },
            {
                key: "display_index",
                title: t("displayOrder"),
                align: "center",
                width: 180,
                render(row) {
                    return h(ShowOrEdit, {
                        value: row.display_index,
                        type: "number",
                        onUpdateValue(v) {
                            row.display_index = v;
                        },
                    });
                },
            },
            {
                key: "column_visible",
                title: t("visible"),
                align: "center",
                width: 180,
                render(row) {
                    return h(NSwitch, {
                        value: row.column_visible,
                        onUpdateValue(v) {
                            row.column_visible = v;
                        },
                    });
                },
            },
        ];

        // 获取数据
        const tableData = ref([]);
        const getDataList = () => {
            tableLoading.value = true;
            getColumnRulesApi({
                webTableName: props.webTableName.replace(/List/g, ""),
                type: props.type,
            })
                .then(res => {
                    tableLoading.value = false;
                    tableData.value = res;
                })
                .catch(() => {
                    tableLoading.value = false;
                });
        };

        // 显示回调
        const onShow = () => {
            getDataList();
        };

        // 提交
        const onSubmit = () => {
            loading.value = true;
            return new Promise((resolve, reject) => {
                saveColumnRulesApi({
                    type: props.type,
                    data: [...tableData.value],
                })
                    .then((res) => {
                        loading.value = false;
                        if (res) {
                            emit("submitSuccess");
                            window.$message.success(t("successMsg"));
                            resolve();
                        }
                    })
                    .catch(() => {
                        loading.value = false;
                        reject();
                    });
            });
        };
        const onCancel = () => {
            console.log("onCancel");
        };

        return {
            show,
            columns,
            loading,
            tableLoading,
            tableData,
            onSubmit,
            onCancel,
            onShow,
        };
    },
});

const ShowOrEdit = defineComponent({
    props: {
        value: [String, Number],
        type: {type: String, default: "text"},
        onUpdateValue: [Function, Array],
    },
    setup(props) {
        const isEdit = ref(false);
        const inputRef = ref(null);
        const inputValue = ref(props.value);

        function handleOnClick() {
            isEdit.value = true;
            nextTick(() => {
                inputRef.value.focus();
            });
        }

        function handleChange() {
            props.onUpdateValue(inputValue.value);
            isEdit.value = false;
        }

        function getComponent() {
            return props.type === "text" ? NInput : NInputNumber;
        }

        return () =>
            h(
                "div",
                {
                    style: {height: "28px", lineHeight: "28px"},
                    onClick: handleOnClick,
                },
                isEdit.value
                    ? h(getComponent(), {
                        ref: inputRef,
                        value: inputValue.value,
                        size: "small",
                        style: {fontSize: "12px"},
                        onUpdateValue: (v) => {
                            inputValue.value = v;
                        },
                        showButton: false,
                        onBlur: handleChange,
                    })
                    : props.value
            );
    },
});
</script>
import {h, computed, defineComponent} from "vue";
import {NButton, NDropdown, NInput} from "naive-ui";

/**
 * 操作按钮面板
 */
export default defineComponent({
    name: "ActionPanel",
    props: {
        simpleSearch: {type: String, default: ""},
        actionRules: {type: Array, default: []},
        mode: {type: String, default: ""},
        disabled: {type: Boolean, default: false},
    },
    emits: ["actionButtonClick", "update:simpleSearch"],
    setup(props, {emit, slots}) {
        // 简单查询
        const simpleSearchVal = computed({
            get: () => props.simpleSearch,
            set: (val) => {
                emit("update:simpleSearch", val);
            }
        });
        // 点击事件
        const onclick = (act) => {
            emit("actionButtonClick", act);
        };
        // 渲染
        return () => h("div", {class: "actions-wrapper"},
            [...props.actionRules?.map(item => {
                if (!item.visible_mode || (item.visible_mode && item.visible_mode.length === 0) || (item.visible_mode && item.visible_mode.indexOf(props.mode) >= 0)) {
                    if (item.type === "button")
                        return h(NButton, {
                            ...item.props, ...{
                                size: "small",
                                options: item.options,
                                disabled: props.disabled,
                                onClick: () => onclick(item.action)
                            }
                        }, {default: () => item.title});
                    if (item.type === "dropdown")
                        return h(NDropdown, {
                            ...item.props, ...{
                                size: "small",
                                showArrow: true,
                                options: item.options,
                                onSelect: onclick
                            }
                        }, {
                            default: () => h(NButton, {
                                size: "small",
                                disabled: props.disabled,
                            }, {default: () => [h("span", {style: {paddingRight: "5px"}}, {default: () => item.title}), h("i", {class: "ri-arrow-down-s-line"})]})
                        });
                    if (item.type === "input")
                        return h(NInput, {
                            ...item.props, ...{
                                size: "small",
                                disabled: props.disabled,
                                "onUpdate:value": (value) => {
                                    simpleSearchVal.value = value;
                                },
                                onClear: () => {
                                    simpleSearchVal.value = "";
                                    onclick("search");
                                },
                                onKeydown: (e) => {
                                    if (e.key === "Enter" && simpleSearchVal.value) {
                                        onclick("search");
                                    }
                                }
                            }
                        });
                    return h(item.type, item.props);
                }
            }),
                // 插槽
                h("div", slots.default?.())]
        );
    }
});
<template>
    <!-- 从表表格 -->
    <div>
        <vxe-table
            ref="xTable"
            border
            row-key
            show-footer
            show-overflow
            align="center"
            header-align="center"
            footer-align="center"
            class="nx-xtable"
            cell-class-name="nx-xtable-cell"
            header-row-class-name="nx-xtable-header"
            footer-row-class-name="nx-xtable-footer"
            keep-source
            empty-text=" "
            :height="height"
            :data="tableDataRef"
            :resizable="true"
            :cell-style="cellStyle"
            :footer-method="footerMethod"
            :highlight-hover-row="true"
            :row-config="{ height: 40 }"
            :mouse-config="{ selected: true }"
            :checkbox-config="{ range: true }"
            :column-config="{ resizable: true }"
            :edit-config="{ trigger: 'click', mode: 'cell', showIcon: false, autoClear: false, beforeEditMethod: activeRowMethod }"
            :merge-cells="mergeCells"
            :keyboard-config="tableKeyboardConfig"
            @cell-mouseenter="cellMouseenterEvent"
            @cell-mouseleave="cellMouseleaveEvent"
            @cell-click="cellClick"
            @cell-dblclick="cellDbclick"
        >
            <vxe-column
                v-if="mode !== 'detail'"
                type="checkbox"
                width="35"
            ></vxe-column>
            <template v-for="(config) in tableColumnRef">
                <vxe-column
                    v-if="mode === 'detail' || (mode !== 'detail' && !editHideColumns.includes(config.key))"
                    :key="config.key"
                    :type="config.key === 'seq' ? 'seq' : null"
                    :field="config.field"
                    :title="config.title"
                    :width="config.width"
                    :cell-type="config.component"
                    :edit-render="editRender(config)"
                >
                    <!-- 行号 -->
                    <template
                        v-if="config.key === 'seq'"
                        #default="{ row, rowIndex }"
                    >
                        <div
                            v-if="mode !== 'detail' && rowActions.length && currnetRow && currnetRow._X_ROW_KEY === row._X_ROW_KEY"
                            class="nx-xtable-row-action-wrapper"
                        >
                            <n-button
                                v-if="rowActions.includes('insert')"
                                strong
                                size="tiny"
                                type="primary"
                                class="action-button"
                                @click="insertRow(-1)"
                            >
                                <i class="ri-add-line"></i>
                            </n-button>
                            <n-button
                                v-if="rowActions.includes('remove')"
                                strong
                                size="tiny"
                                type="error"
                                class="action-button"
                                @click="removeRow(rowIndex)"
                            >
                                <i class="ri-close-line"></i>
                            </n-button>
                            <n-button
                                v-if="rowActions.includes('sortable')"
                                strong
                                size="tiny"
                                type="info"
                                class="action-button drag-btn"
                                @click="dropRow"
                            >
                                <i class="ri-drag-move-2-fill"></i>
                            </n-button>
                            <n-button
                                v-if="rowActions.includes('image')"
                                strong
                                size="tiny"
                                type="warning"
                                class="action-button"
                                @click="uploadImage('uploadMultipleImageRight')"
                            >
                                <i class="ri-image-2-line"></i>
                            </n-button>
                        </div>
                        <span v-else>{{ row.seq }}</span>
                    </template>
                </vxe-column>
            </template>
        </vxe-table>
    </div>
    <!-- 图片上传 单张 -->
    <input
        ref="uploadSingleImageRef"
        type="file"
        accept="image/*"
        style="display: none"
        @change="doUploadImages"
    />
    <!-- 图片上传 多张 -->
    <input
        ref="uploadMultipleImageRef"
        type="file"
        accept="image/*"
        multiple="multiple"
        style="display: none"
        @change="doUploadImages"
    />
    <!-- 图片预览 -->
    <vue-preview
        ref="preview"
        :hideOnClickModal="true"
    ></vue-preview>
</template>
<script setup>
import {ref, computed, nextTick, onMounted, onUnmounted} from "vue";
import {uploadImagesApi} from "@/api";
import VuePreview from "vue3-preview";
import Sortable from "sortablejs";
import i18n from "@/language";
import Decimal from "decimal.js";

// 语言
const {t} = i18n.global;

const props = defineProps({
    mode: {type: String, default: "detail"},
    editHideColumns: {type: Array, default: []},
    autoSelect: {type: Boolean, default: true},
    sumColumns: {type: Array, default: []},
    tableColumn: {type: Array, default: []},
    tableData: {type: Array, default: []},
    tableProps: {type: Object, default: {}},
    removeKeys: {type: Array, default: []},
    height: {type: Number, default: 0},
    rowActions: {
        type: Array,
        default: ["insert", "remove", "sortable", "image"],
    },
    mergeCells: {type: Array, default: []},
    copyRowRemoveProperty: {type: Array, default: ["pk"]},
    autoInsertEmptyRow: {type: Boolean, default: true},
});

const emits = defineEmits([
    "update:tableData",
    "update:removeKeys",
    "editCellChanged",
    "editCellFocus",
    "editCellBlur",
    "removedRows",
]);

// 编辑表格
const xTable = ref();
// 图片预览
const preview = ref();
// 表格列配置
const tableColumnRef = computed({
    get: () => props.tableColumn,
    set: () => {
    }
});
// 表格数据
const tableDataRef = computed({
    get: () => props.tableData,
    set: (val) => {
        emits("update:tableData", val);
    },
});
// 删除行的主键
const removeKeysRef = computed({
    get: () => props.removeKeys,
    set: (val) => {
        emits("update:removeKeys", val);
    },
});

// 编辑渲染器配置
const editRender = (config) => {
    let er = {
        name: "$input",
        immediate: true,
        autoselect: props.autoSelect,
        autofocus: ".vxe-input--inner",
        props: {
            disabled: false,
        },
        events: {
            change: handleEditCellChanged,
            focus: handleEditCellFocus,
            blur: handleEditCellBlur,
        },
    };
    switch (config.component) {
        case "text":
            er.props = {type: "text"};
            break;
        case "number":
            er.props = {type: "number", controls: false};
            break;
        case "date":
            er.props = {type: "date"};
            break;
        case "month":
            er.props = {type: "month"};
            break;
        case "textarea":
            er.name = "textarea";
            er.attrs = {
                size: "small",
                rows: 6,
                class: "vxe-textarea--inner",
                style: {
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    zIndex: 9,
                    backgroundColor: "#fff",
                },
            };
            er.autofocus = ".vxe-textarea--inner";
            er.events.input = handleEditCellChanged;
            break;
        case "selection":
            er.name = "cellSelection";
            er.options = config.options;
            er.autofocus = ".n-base-selection-input";
            break;
        case "image":
            er.name = "cellImage";
            break;
    }
    er.props.placeholder = "";
    er.props.disabled = config.disabled;
    er.props.maxlength = config.maxlength;
    er.props.max = config.max;
    er.props.min = config.min;
    return er;
};

// 禁用单元格
const activeRowMethod = ({rowIndex, column}) => {
    if (props.mode === "detail") {
        return false;
    }
    if (column.field === "seq") {
        return false;
    }
    // if (
    //     props.tableProps[rowIndex] &&
    //     props.tableProps[rowIndex][column.field]
    // ) {
    //     if (props.tableProps[rowIndex][column.field]?.disabled) {
    //         return false;
    //     }
    // }
    return true;
};

// 获取新行号
const getSeqKey = () => {
    let max = Math.max(...tableDataRef.value.map((item) => item.seq));
    return max > 0 ? max + 1 : 1;
};
// 重置顺序编号
const resetSeqKeys = () => {
    tableDataRef.value.forEach((item, index) => {
        tableDataRef.value[index].seq = index + 1;
        tableDataRef.value[index].sort = index + 1;
        if (item._EDIT_FLAG === "default") {
            tableDataRef.value[index]._EDIT_FLAG = "update";
        }
    });
};

// 合计行
const footerMethod = ({columns, data}) => {
    return [
        columns.map((column) => {
            if (column.field === "seq") {
                return t("total");
            }
            if (props.sumColumns.includes(column.field)) {
                return data.reduce((total, row) => {
                    return Decimal.add(
                        total,
                        Number(row[column.field]) || 0
                    ).toNumber();
                }, 0);
            }
            return null;
        }),
    ];
};

/***  ========================== 表格编辑回调事件 ========================== ***/

// 编辑单元格值改变
const handleEditCellChanged = ({row, column}) => {
    emits("editCellChanged", row, column);
    handleTableItemChanged(row);
};
// 编辑单元格获得焦点
const handleEditCellFocus = ({row, column}) => {
    emits("editCellFocus", row, column);
};
// 编辑单元格失去焦点
const handleEditCellBlur = ({row, column}) => {
    emits("editCellBlur", row, column);
};
// 表格编辑时
const handleTableItemChanged = (row) => {
    // 设置编辑标志为更新
    if (row._EDIT_FLAG === "default") {
        row._EDIT_FLAG = "update";
    }
    // 编辑最后一行时添加空白行
    if (props.autoInsertEmptyRow && xTable.value.getRowIndex(row) === tableDataRef.value.length - 1) {
        insertRow(-1);
    }
};

/***  ========================== 表格行鼠标键盘事件处理 ========================== ***/

// 表格键盘事件配置
const tableKeyboardConfig = {
    isArrow: true,
    isTab: true,
    isEdit: true,
};
// 监听键盘事件
const onKeyDown = (e) => {
    if (
        props.mode === "detail" ||
        !["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
        return;
    }
    const cell =
        xTable.value?.getSelectedCell() || xTable.value?.getEditRecord();
    if (!cell) {
        return;
    }
    // 下拉框时禁用上下移动
    if (
        cell.column.cellType === "selection" &&
        ["ArrowUp", "ArrowDown"].includes(e.key)
    ) {
        return;
    }
    let {rowIndex, columnIndex} = cell;
    const columns = xTable.value.getColumns();
    const numRows = tableDataRef.value.length - 1;
    const numColumns = columns.length - 1;
    // if (numColumns === columnIndex) {
    //     rowIndex += 1;
    // }
    if (xTable.value?.getEditRecord()) {
        switch (e.key) {
            case "ArrowUp":
                rowIndex = Math.max(0, rowIndex - 1);
                break;
            case "ArrowDown":
                rowIndex = Math.min(numRows, rowIndex + 1);
                break;
            case "ArrowLeft":
                columnIndex = Math.max(0, columnIndex - 1);
                break;
            case "ArrowRight":
                rowIndex += numColumns === columnIndex ? 1 : 0;
                columnIndex = numColumns > columnIndex ? columnIndex + 1 : 2;
                break;
            default:
                return;
        }
    }
    e.preventDefault();
    const row = tableDataRef.value[rowIndex];
    const field = columns[columnIndex].field;
    xTable.value.setSelectCell(row, field);
    xTable.value.setEditCell(row, field);
};
// 当前鼠标悬停行
const currnetRow = ref(null);
// 鼠标进入单元格
const cellMouseenterEvent = ({row}) => {
    currnetRow.value = row;
};
// 鼠标移出单元格
const cellMouseleaveEvent = () => {
    currnetRow.value = null;
};
// 单元格单击事件
const activeCell = ref();
const cellClick = (cell) => {
    activeCell.value = cell;
};
// 单元格双击事件
const cellDbclick = ({row, column}) => {
    // 图片预览
    if (column.cellType === "image") {
        const xt = column.field;
        const dt = xt.replace("tu", "datu");
        console.log("图片预览 替换大图", xt, dt, row[dt],row[xt]);
        if (row[dt] || row[xt]) {
            preview.value?.open(row[dt] ?? row[xt]);
        }
    }
};

/***  ========================== 表格行操作 ========================== ***/

/**
 * 表格插入行
 * @param {number|null} rowIndex 插入位置 -1：在表格最后插入，null：在表格勾选行前插入
 * @param {object} rowData 插入行数据
 */
const insertRow = (rowIndex = null, rowData = {}) => {
    nextTick(async () => {
        if (rowIndex === null) {
            let checked = xTable.value.getCheckboxRecords();
            if (checked.length !== 1) {
                window.$message.error(t("insertRowMsg"));
                return;
            }
            rowIndex = xTable.value.getRowIndex(checked[0]);
        }

        // 把数据插入到表格末尾，空行前面
        if (Object.keys(rowData).length > 0) {
            rowIndex = 0;
            for (let i = tableDataRef.value.length - 1; i >= 0; i--) {
                let isEmpty = Object.keys(tableDataRef.value[i])
                    .filter(
                        (key) =>
                            ![
                                "seq",
                                "sort",
                                "_X_ROW_KEY",
                                "_EDIT_FLAG",
                            ].includes(key)
                    )
                    .every((key) => {
                        return (
                            tableDataRef.value[i][key] === null ||
                            tableDataRef.value[i][key] === ""
                        );
                    });
                if (!isEmpty) {
                    rowIndex = i + 1;
                    break;
                }
            }
        } else {
            rowIndex = rowIndex === -1 ? tableDataRef.value.length : rowIndex;
        }

        let key = getSeqKey();
        [rowData.seq, rowData.sort, rowData._EDIT_FLAG] = [key, key, "insert"];
        const newRow = await xTable.value.createRow(rowData);
        tableDataRef.value.splice(rowIndex, 0, newRow);
        xTable.value.loadData(tableDataRef.value);
        resetSeqKeys();
    });
};
/**
 * 表格删除行
 * @param {number|null} rowIndex 删除对应下标的行，null：删除勾选的行
 */
const removeRow = (rowIndex = null) => {
    nextTick(() => {
        let rows = [];
        let keys = [];
        if (rowIndex === null) {
            rows = xTable.value.getCheckboxRecords();
            if (rows.length === 0) {
                window.$message.error(t("removeRowMsg"));
                return;
            }
            rows.forEach((row) => {
                keys.push(xTable.value.getRowIndex(row));
            });
        } else {
            rows.push(tableDataRef.value[rowIndex]);
            keys.push(rowIndex);
        }
        // 判断是否可以删除
        for (const row of rows) {
            if (row?.isLowerModuleExist && row.isLowerModuleExist === 1) {
                window.$message.error(t("deleteRowErrorMsg", {seq: row.seq}));
                return;
            }
        }
        keys.sort((a, b) => b - a);
        keys.forEach((key) => {
            tableDataRef.value.splice(key, 1);
        });
        emits("removedRows", rows);
        // 若全删了则添加一行
        if (props.autoInsertEmptyRow && !tableDataRef.value.length) {
            insertRow(-1);
        }
        removeKeysRef.value.push(
            ...rows
                .filter((row) => row._EDIT_FLAG !== "insert")
                .map((row) => row.pk)
        );
        resetSeqKeys();
    });
};
/**
 * 表格复制行
 */
const copyRow = () => {
    nextTick(async () => {
        let rows = xTable.value.getCheckboxRecords();
        if (rows.length !== 1) {
            window.$message.error(t("copyRowMsg"));
            return;
        }
        let record = JSON.parse(JSON.stringify(rows[0]));
        let index = xTable.value.getRowIndex(rows[0]);
        record._EDIT_FLAG = "insert";
        // 删除不需要复制的属性
        delete record._X_ROW_KEY;
        props.copyRowRemoveProperty.map(item => {
            if (record.hasOwnProperty(item)) {
                delete record[item];
            }
        });
        const newRow = await xTable.value.createRow(record);
        tableDataRef.value.splice(index + 1, 0, newRow);
        resetSeqKeys();
    });
};
// 表格移动行
const sortable = ref();
const dropRow = () => {
    nextTick(() => {
        sortable.value = new Sortable(
            xTable.value.$el.querySelector(".vxe-table--body tbody"),
            {
                handle: ".drag-btn",
                onEnd: ({newIndex, oldIndex}) => {
                    const temp = tableDataRef.value[oldIndex];
                    tableDataRef.value[oldIndex] = tableDataRef.value[newIndex];
                    tableDataRef.value[newIndex] = temp;
                    [
                        tableDataRef.value[newIndex].seq,
                        tableDataRef.value[oldIndex].seq,
                        tableDataRef.value[newIndex].sort,
                        tableDataRef.value[oldIndex].sort,
                    ] = [
                        tableDataRef.value[oldIndex].seq,
                        tableDataRef.value[newIndex].seq,
                        tableDataRef.value[oldIndex].sort,
                        tableDataRef.value[newIndex].sort,
                    ];
                    if (tableDataRef.value[newIndex]._EDIT_FLAG === "default") {
                        tableDataRef.value[newIndex]._EDIT_FLAG = "update";
                    }
                    if (tableDataRef.value[oldIndex]._EDIT_FLAG === "default") {
                        tableDataRef.value[oldIndex]._EDIT_FLAG = "update";
                    }
                    xTable.value.loadData(tableDataRef.value);
                },
            }
        );
    });
};

/***  ========================== 图片操作 ========================== ***/

// 上传图片
const uploadSingleImageRef = ref();
const uploadMultipleImageRef = ref();
let imageActionType = "";
const uploadImage = (act) => {
    let cell = xTable.value.getSelectedCell();
    if (!cell) cell = activeCell.value;
    if (!cell) {
        window.$message.error("请选择一个要操作的图片的单元格！");
        return;
    }
    switch (act) {
        case "uploadSingleImage":
        case "deleteSingleImage":
            imageActionType = "single";
            break;
        case "uploadMultipleImageDown":
        case "deleteMultipleImageDown":
            imageActionType = "down";
            break;
        case "uploadMultipleImageRight":
        case "deleteMultipleImageRight":
            imageActionType = "right";
            break;
    }
    switch (act) {
        case "uploadSingleImage":
            uploadSingleImageRef.value.dispatchEvent(new MouseEvent("click"));
            break;
        case "uploadMultipleImageDown":
        case "uploadMultipleImageRight":
            uploadMultipleImageRef.value.dispatchEvent(new MouseEvent("click"));
            break;
        case "deleteSingleImage":
        case "deleteMultipleImageDown":
        case "deleteMultipleImageRight":
            updateImages(["", "", "", "", "", "", "", "", "", ""]);
            break;
    }
};
// 上传图片
const doUploadImages = (e) => {
    let formData = new FormData();
    let files = e.target.files;
    let length = files.length;
    for (let i = 0; i < length; i++) {
        formData.append("files[" + i + "]", files[i]);
    }
    uploadImagesApi(formData)
        .then((res) => {
            uploadSingleImageRef.value.value = "";
            uploadMultipleImageRef.value.value = "";
            if (res) {
                updateImages(res);
            }
            activeCell.value = null;
        })
        .catch(() => {
            window.$message.error("上传失败！");
            uploadSingleImageRef.value.value = "";
            uploadMultipleImageRef.value.value = "";
        });
};
// 更新图片
const updateImages = (urls) => {
    const {tableData} = xTable.value.getTableData();
    let cell = xTable.value.getSelectedCell();
    if (!cell) cell = activeCell.value;
    let columns = tableColumnRef.value.filter(
        (item) => item.component === "image"
    );
    if (cell && columns) {
        let fields = columns.map((item) => item.key);
        console.log("---image columns:", fields);
        if (cell.column.cellType === "image") {
            fields = fields.slice(fields.indexOf(cell.column.field));
        }
        // 单个图片
        if (imageActionType === "single") {
            tableData[cell.rowIndex][fields[0]] = urls[0]["xiaotu"];
            tableData[cell.rowIndex]["datu1"] = urls[0]["yuantu"];
            if (tableData[cell.rowIndex]._EDIT_FLAG === "default") {
                tableData[cell.rowIndex]._EDIT_FLAG = "update";
            }
        }
        // 向右
        if (imageActionType === "right") {
            fields.forEach((field, i) => {
                if (urls.length > i) {
                    tableData[cell.rowIndex][field] = urls[i]["xiaotu"];
                    tableData[cell.rowIndex]["datu" + (i + 1)] =
                        urls[i]["yuantu"];
                    if (tableData[cell.rowIndex]._EDIT_FLAG === "default") {
                        tableData[cell.rowIndex]._EDIT_FLAG = "update";
                    }
                }
            });
        }
        // 向下
        if (imageActionType === "down") {
            for (let i = cell.rowIndex; i < tableData.length; i++) {
                if (urls.length > i) {
                    let n = fields[0].match(/\d+/g);
                    tableData[i][fields[0]] = urls[i]["xiaotu"];
                    tableData[i]["datu" + n[0]] = urls[i]["yuantu"];
                    if (tableData[i]._EDIT_FLAG === "default") {
                        tableData[i]._EDIT_FLAG = "update";
                    }
                }
            }
        }
        // xTable.value.loadData(fullData);
    }
};

/***  ========================== 单元格样式 ========================== ***/

const successStyle = {
    backgroundColor: "#36ad6a",
    color: "#fff",
    fontWeight: "800",
};
const errorStyle = {
    backgroundColor: "#de576d",
    color: "#fff",
    fontWeight: "800",
};
const warningStyle = {
    backgroundColor: "#fcb040",
    color: "#fff",
    fontWeight: "800",
};
const cellStyle = ({row, column}) => {
    if (
        props.tableProps[row._X_ROW_KEY] &&
        props.tableProps[row._X_ROW_KEY]?.row
    ) {
        return warningStyle;
    }
    if (
        props.tableProps[row._X_ROW_KEY] &&
        props.tableProps[row._X_ROW_KEY][column.field]
    ) {
        return errorStyle;
    }
    if (props.tableProps?.cols && props.tableProps.cols[column.field] ) {
        return props.tableProps.cols[column.field];
    }
    if (row?.style) {
        return row.style;
    }
};

onMounted(() => {
    dropRow();
    document.addEventListener("keydown", onKeyDown);
});
onUnmounted(() => {
    if (sortable.value) sortable.value.destroy();
    document.removeEventListener("keydown", onKeyDown);
});

// 暴露给父组件
defineExpose({
    xTable,
    insertRow,
    removeRow,
    copyRow,
    uploadImage,
});
</script>
import http from '@/utils/http'

// 验证码
export const verifyCode = (params) => http.get('/login/verify', { params });
// 账号登录
export const accountLogin = (params) => http.post('/login/account', params);
// 微信登录
export const getUserInfo = (params) => http.post('/login/weixin', params);
// 微信登录
export const weChatConfig = (params) => http.post('/login/weChatConfig', params);
// 切换公司
export const changeCustomerApi = (params) => http.get('/user/changeCustomer', { params });
// 切换账套
export const changeSetofbookApi = (params) => http.get('/user/changeSetofbook', { params });
// 切换语言
export const changeLanguageApi = (params) => http.get('/user/changeLanguage', { params });


// 获取页面配置
export const getActionRulesApi = (params) => http.get('rules/action', { params });
// 获取页面配置
export const getSearchRulesApi = (params) => http.get('rules/search', { params });
// 获取表格列配置
export const getColumnRulesApi = (params) => http.get('rules/columns', { params });
// 保存表格列配置
export const saveColumnRulesApi = (params) => http.post('/rules/columns/save', params);




// 获取表单规则
export const getFormRulesApi = (params) => http.get('/form/rules', params);
// 更新规则
export const updateRulesApi = (params) => http.get('/empmas/updateRules', { params });
// 获取表单规则
export const formRulesApi = (params, webTableName) => http.get(webTableName + '/getRules', { params });
// 邀请链接
export const inviteAddress = (params) => http.post('/empmas/invite', params);
// 图片上传
export const uploadImageApi = (params) => http.post('/test/qiniu', params);
export const uploadImagesApi = (params) => http.post('/test/multipartUploadQiniu', params, {'Content-Type': 'multipart/form-data'});
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden"
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
      @onChange="handleChange"
    />
  </div>
</template>
<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import {getItem} from "@/utils/storage";

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
const props = defineProps();
// 内容 HTML
const valueHtml = ref("");
const mode = ref("default");
const editor = editorRef.value;

onMounted(() => {});

const toolbarConfig = {};
// MENU_CONF格式化必填，否则后面会报错
const editorConfig = { MENU_CONF: {} };

// https://www.wangeditor.com/v5/menu-config.html#上传图片
// 图片后端返回格式
// 上传成功的返回格式
// {
//     "errno": 0, // 注意：值是数字，不能是字符串
//     "data": {
//         "url": "xxx", // 图片 src ，必须
//         "alt": "yyy", // 图片描述文字，非必须
//         "href": "zzz" // 图片的链接，非必须
//     }
// }

// 上传失败的返回格式
// {
//     "errno": 1, // 只要不等于 0 就行
//     "message": "失败信息"
// }
editorConfig.MENU_CONF['uploadImage'] = {
  server: process.env.VUE_APP_BASE_URL + '/test/qiniu', // 上传图片地址
  timeout: 60 * 1000, // 5s
  fieldName: 'file',
  metaWithUrl: true, // 参数拼接到 url 上
  headers: { "authorization": getItem("TOKEN") },
  maxFileSize: 10 * 1024 * 1024, // 10M
  base64LimitSize: 5 * 1024, // 5kb 以下插入 base64
  onBeforeUpload(files) {
    console.log('onBeforeUpload', files)
    return files // 返回哪些文件可以上传
    // return false 会阻止上传
  },
  // onProgress(progress) {
  //   console.log('onProgress', progress)
  // },
  // onSuccess(file, res) {
  //   console.log('onSuccess', file, res)
  // },
  onFailed(file, res) {
    window.$message.error(res.errmsg)
    console.log('onFailed', file, res)
  },
  onError(file, err, res) {
    window.$message.error(res.errmsg)
    console.error('onError', file, err, res)
  },

  // // 用户自定义插入图片
  // customInsert(res, insertFn) {
  //   console.log('customInsert', res)
  //   const imgInfo = res.data[0] || {}
  //   const { url, alt, href } = imgInfo
  //   if (!url) throw new Error(`Image url is empty`)

  //   // 自己插入图片
  //   console.log('自己插入图片', url)
  //   insertFn(url, alt, href)
  // },

  // // 用户自定义上传图片
  // customUpload(file, insertFn) {
  //   console.log('customUpload', file)

  //   return new Promise((resolve) => {
  //     // 插入一张图片，模拟异步
  //     setTimeout(() => {
  //       const src = `https://www.baidu.com/img/flexible/logo/pc/result@2.png?r=${Math.random()}`
  //       insertFn(src, '百度 logo', src)
  //       resolve('ok')
  //     }, 500)
  //   })
  // },
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  if (editor == null) return;
  editor.destroy();
});

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
  if(props.formCreateInject.rule.value){
    editorRef.value.setHtml(props.formCreateInject.rule.value);
  }
};
const handleChange = (editor) => {
  let imgRegEx = /<img.*?>/gi;
  props.formCreateInject.rule.value = editor.getHtml();
  let richText = props.formCreateInject.rule.value;
  props.formCreateInject.rule.value = richText.replace(imgRegEx, (match) => {
    return match.replace(/<img/, '<img style="width:100%;"');
  });
};
</script>    
<template>
    <n-modal
        v-model:show="show"
        preset="dialog"
        transformOrigin="center"
        to="#pageRoot"
        :mask-closable="false"
        :showIcon="false"
        :title="title"
        :loading="loading"
        :style="{ width: width+'px' }"
        :footer-style="{ textAlign: 'right' }"
        :positive-text="$t('save')"
        :negative-text="$t('cancel')"
        :negative-button-props="{ disabled:loading }"
        @positive-click="onSubmit"
        @after-leave="onCancel"
    >
        <n-scrollbar class="form-modal-wrapper">
            <form-create
                v-model="formDataRef"
                v-model:api="formApi"
                :rule="formRulesRef"
                :option="formOptions"
            />
        </n-scrollbar>
    </n-modal>
</template>
<script setup>
import {computed, ref, watch} from "vue";
import http from "@/utils/http";
import i18n from "@/language";

const {t} = i18n.global;

const props = defineProps({
    visible: {type: Boolean, default: false},
    title: {type: String, default: "No Title"},
    width: {type: Number, default: 700},
    labelWidth: {type: Number, default: 100},
    formRules: {type: Array, default: []},
    formData: {type: Object, default: {}},
    postApi: String,
    beforeSubmit: Function,
});
const emits = defineEmits([
    "submitCancel",
    "submitSuccess",
    "submitError",
    "update:visible",
    "update:formRules",
    "update:formData",
]);
const show = computed({
    get: () => props.visible,
    set: (val) => {
        emits("update:visible", val);
    },
});
const formRulesRef = computed({
    get: () => [
        ...props.formRules.map((item) => {
            item.props["onUpdate:value"] = () => {
                item.props.status = "success";
            };
            return item;
        })
    ],
    set: (val) => {
        emits("update:formRules", val);
    },
});
const formDataRef = computed({
    get: () => props.formData,
    set: (val) => {
        emits("update:formData", val);
    },
});
const formOptions = {
    form: {
        size: "small",
        showFeedback: false,
        labelWidth: props.labelWidth,
        labelAlign: "left",
        labelPlacement: "left",
    },
    row: {
        gutter: [10, 10],
    },
    submitBtn: false,
};
watch(() => props.labelWidth, () => {
    formOptions.form.labelWidth = props.labelWidth;
});

/**
 * 设置表单状态
 * @param {string} field 表单字段
 * @param {string} status 状态 [ 'success' | 'warning' | 'error' ]
 */
const setFormItemStatus = (field, status = "error") => {
    formRulesRef.value.forEach((item) => {
        if (item.field === field) {
            item.props.status = status;
        }
    });
};

let formApi = {};
const loading = ref(false);

// 提交表单 调用自定义前置方法
const onSubmit = () => {
    loading.value = true;
    return new Promise((resolve, reject) => {
        formApi.submit((formData) => {
            if (typeof props.beforeSubmit === "function") {
                const before = props.beforeSubmit(formData);
                if (before === false) {
                    loading.value = false;
                    reject();
                    return;
                }
            }
            formDataRef.value = formData;
            // 发起请求
            http.post(props.postApi, formData)
                .then((res) => {
                    window.$message.success(t("successMsg"));
                    emits("submitSuccess", res);
                    loading.value = false;
                    resolve();
                })
                .catch((err) => {
                    emits("submitError", err);
                    loading.value = false;
                    setFormItemStatus(err.data?.err_field);
                    reject();
                });
        }, (err, e2) => {
            console.log("-------------", err, e2);
        });
    });
};

// 取消
const onCancel = () => {
    if (formDataRef.value) {
        formDataRef.value = {};
    }
    emits("update:formData");
    emits("update:visible");
    emits("submitCancel");
};
</script>
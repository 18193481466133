/**
 * 去抖
 * @param func
 * @param delay
 * @returns {(function(): void)|*}
 */
export const debounce = (func, delay) => {
    let timerId;
    return function () {
        const args = arguments;
        clearTimeout(timerId);
        timerId = setTimeout(function () {
            func.apply(null, args);
        }, delay);
    };
}
<template>
    <n-modal
        v-model:show="show"
        preset="dialog"
        transformOrigin="center"
        to="#pageRoot"
        :mask-closable="false"
        :showIcon="false"
        :title="title"
        :loading="loading"
        :style="{ width }"
        :footer-style="{ textAlign: 'right' }"
        @after-enter="onShow"
        @after-leave="handleCancel"
    >

        <div class="form-modal-wrapper">
            <!-- 自定义头部插槽 -->
            <div>
                <slot name="custom-header"></slot>
            </div>
            <!-- 搜索表单 -->
            <div
                v-if="useSearchForm"
                class="flex gap mb-10"
            >
                <div class="grow"></div>
                <form-create
                    v-model="expertSearch"
                    v-model:api="formApi"
                    :rule="searchRules"
                    :option="formOptions"
                />
            </div>
            <!-- 表格 -->
            <basic-table
                v-model:selectedKeys="selectedKeys"
                v-model:selectedRows="selectedRows"
                :loading="loading"
                :columns="columns"
                :datasource="datasource"
                :pagination="false"
                :height="500"
                @dbClickRow="(row) => handleSubmit(row)"
            />
        </div>

        <!-- 操作 -->
        <template #action>
            <div class="nx-edit-table-modal-action">
                <div class="place">
                    <n-pagination
                        v-if="usePagination"
                        v-model:page="pagination.page"
                        :page-count="Math.ceil(pagination.total / pagination.size)"
                        @update:page="(page) => getDataList(page)"
                    />
                </div>
                <!-- 自定义操作插槽 -->
                <slot name="custom-action" :loading="loading"></slot>
                <n-button
                    v-if="useCancelBtn"
                    size="small"
                    :disabled="loading"
                    @click="handleCancel"
                >{{ cancelBtn }}
                </n-button>
                <n-button
                    v-if="useSubmitBtn"
                    size="small"
                    type="primary"
                    :disabled="loading"
                    @click="handleSubmit()"
                >{{ submitBtn }}
                </n-button>
            </div>
        </template>

    </n-modal>
</template>
<script setup>
import {computed, ref} from "vue";
import {useDataSource} from "@/composables/dataSource";
import i18n from "@/language";

const {t} = i18n.global;

const props = defineProps({
    webTableName: {type: String, default: ""},
    visible: {type: Boolean, default: false},
    filters: {type: Object, default: {}},
    title: String,
    width: {type: String, default: "80vw"},
    cancelBtn: String,
    submitBtn: String,
    useSearchForm: {type: Boolean, default: true},
    useCancelBtn: {type: Boolean, default: true},
    useSubmitBtn: {type: Boolean, default: true},
    usePagination: {type: Boolean, default: true},
    beforeSubmit: Function,
});

const emits = defineEmits(["update:visible", "submit", "cancel"]);

// 列表数据
const {
    loading,
    columns,
    pagination,
    datasource,
    expertSearch,
    selectedKeys,
    selectedRows,
    searchRules,
    getDataList,
    getPageRules,
} = useDataSource(props.webTableName, "", false);
const show = computed({
    get: () => props.visible,
    set: (val) => {
        emits("update:visible", val);
    },
});

let formApi = {};
const formOptions = ref({
    form: {
        size: "small",
        showFeedback: false,
        labelWidth: 100,
        labelAlign: "left",
        labelPlacement: "left",
    },
    row: {
        gutter: [10, 10],
    },
    submitBtn: false,
});

// 显示时加载数据
const onShow = () => {
    // 合并筛选条件
    expertSearch.value = {...expertSearch.value, ...props.filters};
    getPageRules();
    getDataList();
};

// 提交
const handleSubmit = (rows = null) => {
    if (!rows && selectedRows.value.length < 1) {
        window.$message.error(t("selectRowMsg"));
        return false;
    }
    rows = rows ? [rows] : selectedRows.value;
    if (typeof props.beforeSubmit === "function") {
        if (props.beforeSubmit(rows) === false) {
            return false;
        }
    }
    emits("submit", rows);
    clearAll();
};

// 取消
const handleCancel = () => {
    emits("cancel");
    clearAll();
};

// 清空数据
const clearAll = () => {
    show.value = false;
    columns.value = [];
    datasource.value = [];
    selectedKeys.value = [];
    selectedRows.value = [];
    for (let key in expertSearch.value) {
        expertSearch.value[key] = null;
    }
};

// 供外部使用
defineExpose({
    loading,
    columns,
    pagination,
    datasource,
    expertSearch,
    selectedKeys,
    selectedRows,
    searchRules,
    getDataList,
    getPageRules,
});
</script>
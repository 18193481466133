import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'

//注册i8n实例并引入语言文件
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    global: true,
    locale: 'zh-cn',
    fallbackLocale: 'zh-cn',
    messages: {
        'zh-cn': { ...zh },
        'en-us': { ...en },
    }
})

export default i18n
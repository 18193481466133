import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./language/index";
import {
    create,
    createDiscreteApi,
    NButton,
    NButtonGroup,
    NDropdown,
    NMessageProvider,
    NResult,
    NInput,
    NPagination,
    NDataTable,
    NTabs,
    NTab,
    NTabPane,
    NBreadcrumb,
    NBreadcrumbItem,
    NMenu,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NLayoutSider,
    NForm,
    NFormItem,
    NEllipsis,
    NTooltip,
    NGrid,
    NGridItem,
    NGi,
    NAvatar,
    NSelect,
    NModal,
    NDatePicker,
    NRow,
    NCol,
    NCheckbox,
    NTag,
    NTransfer,
    NList,
    NListItem,
    NInputNumber,
    NTree,
    NSwitch,
    NUpload,
    NUploadDragger,
    NRadio,
    NRadioGroup,
    NRadioButton,
    NImage,
    NInputGroup,
    NInputGroupLabel,
    NScrollbar,
    NSpin,
    NCard,
    NBadge,
    NStatistic,
    NDivider,
    NTable,
    NThing,
} from "naive-ui";

const naive = create({
    components: [
        NButton,
        NButtonGroup,
        NDropdown,
        NMessageProvider,
        NResult,
        NInput,
        NPagination,
        NDataTable,
        NTabs,
        NTab,
        NTabPane,
        NBreadcrumb,
        NBreadcrumbItem,
        NMenu,
        NLayout,
        NLayoutHeader,
        NLayoutContent,
        NLayoutSider,
        NForm,
        NFormItem,
        NEllipsis,
        NTooltip,
        NGrid,
        NGridItem,
        NGi,
        NAvatar,
        NSelect,
        NModal,
        NDatePicker,
        NRow,
        NCol,
        NCheckbox,
        NTag,
        NTransfer,
        NList,
        NListItem,
        NInputNumber,
        NTree,
        NSwitch,
        NUpload,
        NUploadDragger,
        NRadio,
        NRadioGroup,
        NRadioButton,
        NImage,
        NInputGroup,
        NInputGroupLabel,
        NScrollbar,
        NSpin,
        NCard,
        NBadge,
        NStatistic,
        NDivider,
        NTable,
        NThing,
    ]
});
import formCreate from "@form-create/naive-ui";
formCreate.component('Editor');
// vxe-table
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
import "@/utils/vxetableRenderer";

// 图标
import "remixicon/fonts/remixicon.css";
// 全局样式
import "@/assets/style/normalize.css";
import "@/assets/style/default.scss";

// 注册全局组件
import component from "@/components";

// window挂载全局api
const {
    message,
    dialog,
    loadingBar,
    notification
} = createDiscreteApi(["message", "dialog", "loadingBar", "notification"]);
window.$message = message;
window.$dialog = dialog;
window.$loadingBar = loadingBar;
window.$notification = notification;

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(naive)
    .use(formCreate)
    .use(VXETable)
    .use(component)
    .mount("#app");

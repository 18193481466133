import {getStorage, setStorage, clrStorage} from "@/utils/storage";
import {nextTick} from "vue";
import Reouer from "@/router";

const state = {
    isCollapse: getStorage("ISCOLLAPSE") ?? true,
    navigation: getStorage("NAVIGATION") || [],
    tabbar: {bars: [], active: ""},
    keepAlive: [],
    location: [],
    language: getStorage("LANGUAGE") || "zh-cn",
    asyncRoutesMark: false,
};

const getters = {
    menus: (state) => {
        return state.navigation.filter(item => item.visible === 1).map(item => {
            if (item?.children && item.children.length > 0) {
                item.children = item.children.reduce((acc, curr) => {
                    const groupItem = acc.find(item => item.title === curr.group);
                    if (groupItem) {
                        groupItem.children.push(curr);
                    } else {
                        if (curr.visible === 1) {
                            acc.push({
                                id: "g" + curr.id,
                                name: "G" + curr.group,
                                type: "group",
                                path: "",
                                title: curr.group,
                                children: [curr]
                            });
                        }
                    }
                    return acc;
                }, []);
            }
            return item;
        });
    },
};

const mutations = {
    // 动态路由添加标记
    setAsyncRoutesMark: (state, value) => {
        state.asyncRoutesMark = value;
    },
    // 菜单折叠标志
    setIsCollapse: (state) => {
        state.isCollapse = !state.isCollapse;
        setStorage("ISCOLLAPSE", state.isCollapse);
    },
    // 菜单列表
    setNavigation: (state, navigation) => {
        state.navigation = navigation;
        setStorage("NAVIGATION", navigation);
    },
    // 标签栏添加
    addTabbar: (state, tab) => {
        let index = searchTabByName(tab.name);
        if (index > -1) {
            state.tabbar.bars[index].query = tab.query;
        } else {
            state.tabbar.bars.push(tab);
        }
        nextTick(() => {
            state.tabbar.active = tab.name;
        });
    },
    // 标签栏切换
    changeTabbar: (state, name) => {
        const index = searchTabByName(name);
        if (index > -1) {
            state.tabbar.active = state.tabbar.bars[index].name;
            Reouer.push({path: state.tabbar.bars[index].path, query: state.tabbar.bars[index].query});
        }
    },
    // 标签栏移除
    removeTabbar: (state, name) => {
        const {index, nextTab} = getRemoveTabByName(name);
        state.tabbar.bars.splice(index, 1);
        const kindex = state.keepAlive.indexOf(name);
        if (kindex > -1) {
            state.keepAlive.splice(kindex, 1);
        }
        if (state.tabbar.active === name) {
            state.tabbar.active = nextTab.name;
            Reouer.push({path: nextTab.path, query: nextTab.query});
        }
    },
    // 标签栏批量移除
    removeTabbarCommand: (state, command) => {
        if (command === "all") {
            state.tabbar.bars = [];
            state.keepAlive = [];
            Reouer.push("/welcome");
        } else {
            const nextTab = state.tabbar.bars[searchTabByName(state.tabbar.active)];
            state.keepAlive = state.keepAlive.indexOf(state.tabbar.active) > -1 ? [state.tabbar.active] : [];
            state.tabbar.bars = [];
            state.tabbar.bars.push(nextTab);
            state.tabbar.active = nextTab.name;
            Reouer.push({path: nextTab.path, query: nextTab.query});
        }
    },
    // 复位标签栏
    resetTabbar: (state) => {
        state.tabbar = {bars: [], active: ""};
        state.keepAlive = [];
    },
    // 设置缓存
    setKeepAlive: (state, value) => {
        if (state.keepAlive.indexOf(value) === -1) {
            state.keepAlive.push(value);
        }
    },
    // 移除缓存
    removeKeepAlive: (state, value) => {
        let index = state.keepAlive.indexOf(value);
        if (index > -1) {
            state.keepAlive.splice(index, 1);
        }
    },
    // 位置
    setLocation: (state, value) => {
        state.location = value;
    },
    // 缓存语言
    setLanguage: (state, value) => {
        state.language = value;
        setStorage("LANGUAGE", value);
    },
    // 清除缓存
    clear: (state) => {
        state.navigation = [];
        state.location = [];
        state.language = "";
        state.tabbar = {bars: [], active: ""};
        state.keepAlive = [];
        state.asyncRoutesMark = false;
        clrStorage();
    },
};

const actions = {};

const searchTabByName = (name) => {
    let index = -1;
    state.tabbar.bars.forEach((item, ind) => {
        if (item.name === name) {
            index = ind;
        }
    });
    return index;
};
const getRemoveTabByName = (name) => {
    let res = {
        index: searchTabByName(name),
        nextTab: null
    };
    if (res.index > -1) {
        let frontIndex = res.index - 1;
        let endIndex = state.tabbar.bars.length - 1;
        if (frontIndex >= 0) {
            res.nextTab = state.tabbar.bars[frontIndex];
        } else {
            res.nextTab = state.tabbar.bars[endIndex];
        }
    }
    return res;
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
// 组装动态路由
export default function formatRoutes(routes) {
    let accessRoutes = []
    routes.forEach(item => {
        if (item.component) {
            accessRoutes.push({
                path: item.path.replace(/\?.*$/, ''),
                name: item.name,
                component: () => import('@/views'+item.component),
                meta: {
                    title: item.title,
                    location: [item.title],
                    keepAlive: !!item.keep_alive,
                },
            })
        }
        if (item.children?.length > 0) {
            item.children.forEach(childItem => {
                accessRoutes.push({
                    path: childItem.path.replace(/\?.*$/, ''),
                    name: childItem.name,
                    component: () => import('@/views'+childItem.component),
                    meta: {
                        title: childItem.title,
                        location: [item.title, childItem.title],
                        keepAlive: !!childItem.keep_alive,
                    },
                })
            })
        }
    })
    return accessRoutes
}
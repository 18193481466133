<template>
    <n-data-table
        ref="dataTableRef"
        size="small"
        class="nx-data-table"
        :cascade="cascade"
        :row-key="(rowData) => rowData[rowKey]"
        :columns="columnsRef"
        :data="datasource"
        :pagination="paginate"
        :loading="loading"
        :scroll-x="tableWidth"
        :checked-row-keys="checkedKeys"
        :remote="true"
        :striped="true"
        :single-line="false"
        :flex-height="true"
        :default-expand-all="true"
        :paginate-single-page="showPaginate"
        :row-props="rowProps"
        :summary="summaryRow"
        @update:checked-row-keys="handleCheckedTableRow"
        @update:page="handlePageChange"
        @update:page-size="handleSizeChange"
    >
    </n-data-table>
</template>
<script setup>
import {reactive, watch, h, computed, onUpdated, onMounted} from "vue";
import {NImage, NInput} from "naive-ui";
import i18n from "@/language";

// 语言
const {t} = i18n.global;

const props = defineProps({
    rowKey: {type: String, default: "pk"},
    loading: {type: Boolean, default: false},
    height: {type: Number, default: 0},
    columns: {type: Array, default: []},
    datasource: {type: Array, default: []},
    pagination: {type: [Object, Boolean], default: false},
    summaryRow: {type: Function, default: undefined},
    selectedKeys: {type: Array, default: []},
    selectedRows: {type: Array, default: []},
    cascade: {type: Boolean, default: false},
    showPaginate: {type: Boolean, default: true},
});

// 事件
const emits = defineEmits([
    "update:selectedKeys",
    "update:selectedRows",
    "pageChanged",
    "sizeChanged",
    "dbClickRow",
]);

// 选中行
const checkedKeys = computed({
    get: () => props.selectedKeys,
    set: (val) => {
        emits("update:selectedKeys", val);
    },
});
const checkedRows = computed({
    get: () => props.selectedRows,
    set: (val) => {
        emits("update:selectedRows", val);
    },
});

// 计算表格内容宽度
const tableWidth = computed({
    get: () =>
        props.columns.reduce((total, item) => {
            return total + item.width;
        }, 0),
    set: () => {
    }
});

onUpdated(() => {
    // 合计行添加合计标题
    const tr = document.getElementsByClassName("n-data-table-tr--summary")[0];
    if (!tr) return;
    const td = tr.querySelectorAll("[data-col-key='__n_selection__']")[0];
    if (!td) return;
    td.innerHTML = t("total");
});

// 列配置
const columnsRef = computed({
    get: () => [
        ...props.columns.map((item) => {
            let col = {...item};
            // 渲染图片
            if (item.component === "image") {
                Object.assign(col, {
                    render(row) {
                        return row[item.key]
                            ? h(
                                NImage,
                                {
                                    height: 30,
                                    objectFit: "cover",
                                    src: row[item.key],
                                },
                                null
                            )
                            : "";
                    },
                });
            }
            // 渲染输入框
            if (item.component === "input") {
                Object.assign(col, {
                    render(row) {
                        return h(
                            NInput,
                            {
                                size: "small",
                                value: row[item.key] + "",
                                onUpdateValue(v) {
                                    row[item.key] = v;
                                },
                            },
                            null
                        );
                    },
                });
            }
            return col;
        }),
    ],
    set: (val) => val
});

// 默认分页信息
const paginate = reactive({
    page: 1,
    itemCount: 0,
    pageCount: 0,
    pageSize: 20,
    pageSizes: [20, 60, 100],
    showQuickJumper: true,
    showSizePicker: true,
    prefix({itemCount}) {
        return t("pageInfo", {num: itemCount});
    },
});
// 更新分页信息
watch(
    () => props.pagination,
    (npg) => {
        if (npg && typeof props.pagination === "object") {
            paginate.page = npg.page;
            paginate.pageSize = npg.size;
            paginate.itemCount = npg.total;
            paginate.pageCount = Math.ceil(npg.total / npg.size);
        }
    }
);

// 翻页事件
const handlePageChange = (page) => {
    emits("pageChanged", page);
};
const handleSizeChange = (size) => {
    emits("sizeChanged", size);
};

// 选中行
const handleCheckedTableRow = (keys, rows, meta) => {
    // 这里只处理批量操作事件，单行的事件在onClick中处理
    if (["checkAll", "uncheckAll"].includes(meta.action)) {
        checkedKeys.value = keys;
        checkedRows.value = rows;
    }
};

// 自定义事件
const rowProps = (row, index) => {
    // 当前为合计行时，不处理点击事件
    if (row?.summary) return;
    return {
        // 单击
        onClick: () => {
            // 处理选中和取消选中
            let index = checkedKeys.value.indexOf(row[props.rowKey]);
            if (index === -1) {
                checkedKeys.value.push(row[props.rowKey]);
                checkedRows.value.push(row);
            } else {
                checkedKeys.value.splice(index, 1);
                checkedRows.value.splice(index, 1);
            }
        },
        // 双击
        onDblclick: () => {
            emits("dbClickRow", row, index);
        },
    };
};
</script>
import {h} from "vue";
import VXETable from "vxe-table";
import {NSelect} from "naive-ui";
/**
 * VXETable渲染器 添加自定义渲染组件
 */
// 下拉选择
// 可搜索 filterable
// 可新增
VXETable.renderer.add('cellSelection', {
    // 可编辑激活模板
    renderEdit (renderOpts, params) {
        let { row, column } = params;
        return h(NSelect,{
            value: row[column.field],
            options: renderOpts.options,
            disabled: renderOpts.props.disabled,
            placeholder: '',
            filterable: true,
            clearable: true,
            tag: true,
            showCheckmark: false,
            "onUpdate:value": (value) => {
                row[column.field] = value;
                renderOpts.events.change(params);
            }
        });
    },
    // 可编辑显示模板
    renderCell (renderOpts, params) {
        let { row, column } = params;
        let label =  renderOpts.options.find(obj => obj.value === row[column.field])?.label;
        let val = label ?? (row[column.field] ?? null);
        return [<span>{val}</span>];
    },
})
// 图片
VXETable.renderer.add('cellImage', {
    // 可编辑激活模板
    renderEdit (renderOpts, { row, column }) {
        if (!row[column.field]) return h('div',{class:"nx-image-wrapper"});
        return h('div',{class:"nx-image-wrapper"},[
            h('img',{
                src: row[column.field],
                class: 'nx-data-table-cell-image'
            })
        ]);
    },
    // 可编辑显示模板
    renderCell (renderOpts, { row, column }) {
        if (!row[column.field]) return null;
        return h('img',{
            src: row[column.field],
            class: 'nx-data-table-cell-image'
        });
    },
})
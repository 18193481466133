<template>
    <n-config-provider
        :locale="locale"
        :date-locale="dateLocale"
    >
        <router-view />
    </n-config-provider>
</template>
<script setup>
// 加载语言配置
import {getItem} from "@/utils/storage";
import {useI18n} from "vue-i18n";
import {NConfigProvider} from "naive-ui";
import {zhCN, dateZhCN, enUS, dateEnUS} from "naive-ui";

const lang = getItem("LANGUAGE") || "zh-cn";
useI18n({useScope: "global"}).locale.value = lang;
const locale = lang === "zh-cn" ? zhCN : enUS;
const dateLocale = lang === "zh-cn" ? dateZhCN : dateEnUS;

// ResizeObserver防抖
const debounce = (callback, delay) => {
    let tid;
    return function (...args) {
        const ctx = this;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
            callback.apply(ctx, args);
        }, delay);
    };
};
const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
        callback = debounce(callback, 20);
        super(callback);
    }
};

</script>
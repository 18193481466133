<template>
    <!-- 高级查询 -->
    <div
        v-if="visible"
        class="search-wrapper"
    >
        <form-create
            v-model="formData"
            v-model:api="formApi"
            :rule="searchRules"
            :option="formOptions"
        />
    </div>
</template>
<script setup>
import {computed} from "vue";
import i18n from "@/language";

const {t} = i18n.global;

// 参数
const props = defineProps({
    webTableName: String,
    visible: {type: Boolean, default: false},
    searchRules: {type: Array, default: []},
    searchForm: {type: Object, default: {}},
    labelWidth: {type: Number, default: 80},
});
// 事件
const emits = defineEmits(["update:searchForm"]);
// 表单数据
const formData = computed({
    get: () => props.searchForm,
    set: (val) => {
        emits("update:searchForm", val);
    },
});
// 表单对象
let formApi = {};
// 表单配置
const formOptions = {
    form: {
        width: "100%",
        labelWidth: props.labelWidth,
        size: "small",
    },
    row: {
        gutter: [10, 10],
    },
    wrap: {
        labelAlign: "left",
        showFeedback: false,
    },
    submitBtn: false,
    resetBtn: false,
};
</script>
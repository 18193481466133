import axios from 'axios'
import { getItem } from './storage'
import Router from '@/router'
import i18n from '@/language';

// 语言
const { t } = i18n.global;

// 请求计数
let times = 0;

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 1000 * 60
})

http.interceptors.request.use(
    config => {
        config.headers['authorization'] = getItem('TOKEN') || ''
        config.headers['lang'] = getItem('LANGUAGE') || 'zh-cn'
        // 开启加载条，计数累加
        window.$loadingBar.start()
        times++
        return config
    },
    err => {
        Promise.reject(err)
    }
)

http.interceptors.response.use(
    res => {
        // 减计数，最后请求完成时关闭加载条
        times--
        if (res.data.errno === 0) {
            if (times === 0) {
                window.$loadingBar.finish()
            }
            return Promise.resolve(res.data.data)
        } else {
            if (times === 0) {
                window.$loadingBar.error()
            }
            if (res.data.errno === 2001) {
                Router.replace('/login')
            }
            window.$message.error(res.data.errmsg, { keepAliveOnHover: true })
            return Promise.reject(res.data);
        }
    },
    err => {
        times--
        if (times === 0) {
            window.$loadingBar.error()
        }
        window.$message.error(t('serverError'))
        return Promise.reject(err);
    }
)

export default http

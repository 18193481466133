import { getStorage, setStorage, clrStorage } from '@/utils/storage'

const avatar = require('@/assets/images/def-avatar.png')

const state = {
    token: getStorage('TOKEN') || '',
    expiredTime: getStorage('EXPIREDTIME') || '',
    username: getStorage('USERNAME') || '',
    avatar: getStorage('AVATAR') || avatar,
}

const getters = {
    authenticated: (state) => {
        let stu = false
        if (state.token) {
            let now = Date.parse(new Date())
            if (now < state.expiredTime) {
                stu = true
            }
        }
        return stu
    },
}

const mutations = {
    // 缓存token
    setToken: (state, value) => {
        state.token = 'Bearer ' + value
        state.expiredTime = Date.parse(new Date()) + 604800000
        setStorage('TOKEN', state.token)
        setStorage('EXPIREDTIME', state.expiredTime)
    },
    // 缓存用户名
    setUsername: (state, username) => {
        state.username = username
        setStorage('USERNAME', username)
    },
    // 缓存头像
    setAvatar: (state, avatar) => {
        state.avatar = avatar
        setStorage('AVATAR', avatar)
    },
    // 清除缓存
    clear: (state) => {
        state.token = null
        state.avatar = null
        state.username = null
        state.expiredTime = null
        clrStorage()
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
